import {AddItem as AddItemApi} from "../../../services/RegistryService/RegistryService";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const AddItem = ({fetchItems}) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');

  const [state, setState] = useState('');
  const [error, setError] = useState({})

  const submit = (ev) => {
    ev.preventDefault();

    setState('posting');

    AddItemApi({
      title: title,
      subtitle: subtitle,
      description: description,
      amount: amount,
      link: link
    }, image)
      .then(r => {
        if (!r.error) {
          setState('posted');
          fetchItems();
          navigate('../')
          return;
        }

        setState('error')
        setError(r)
      })
      .catch(e => {
        console.error('failed to add', e)
        setState('error')
        setError(e)
      })
  }

  return (
    <>
      <p>Add new</p>
      {
        {
          'posted': <p>Success! Reloading...</p>,
          'error': <div>
            <p>Failed :(</p>
            <p>{JSON.stringify(error)}</p>
          </div>
        }[state]
      }
      <form onSubmit={submit}>
        <TextInput value={title} onChange={(e) => setTitle(e.target.value)} name='title' label='Title' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <TextInput value={subtitle} onChange={(e) => setSubtitle(e.target.value)} id='subTitle' label='Subtitle' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <TextInput value={description} onChange={(e) => setDescription(e.target.value)} id='description' disabled={state === 'posted' || state === 'posting'}
                   label='Description' className='my-1'/>
        <TextInput value={amount} onChange={(e) => setAmount(e.target.value)} type='number' id='amount' disabled={state === 'posted' || state === 'posting'}
                   label='Amount ($)' className='my-1'/>
        <TextInput value={link} onChange={(e) => setLink(e.target.value)} id='link' label='Link' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <div className='my-1'>
          <p className='text-sm block text-gray-700'>Image</p>
          <input type='file' onChange={(e) => setImage(e.target.files[0])}/>
        </div>
        <Button type='submit' className='py-2 px-12 my-4 m-auto' disabled={state === 'posted' || state === 'posting'}>Add</Button>
      </form>
    </>)
}

export default AddItem;