import {EditItem as EditItemApi} from "../../../services/RegistryService/RegistryService";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {ItemImage} from "../ItemList/IndivItem";

const EditItem = ({items, fetchItems}) => {
  var {id} = useParams();
  const navigate = useNavigate();

  let item = null;
  const filteredItems = items.filter(i => i.id === id);
  if (items.length !== 0) {
    item = filteredItems[0]
  }

  const [title, setTitle] = useState(item?.title ?? '');
  const [subtitle, setSubtitle] = useState(item?.subtitle ?? '');
  const [description, setDescription] = useState(item?.description ?? '');
  const [amount, setAmount] = useState(item?.amount ?? 0);
  const [image, setImage] = useState(item?.image ?? '');
  const [link, setLink] = useState(item?.link ?? '');

  const [state, setState] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    setTitle(item?.title ?? '')
    setSubtitle(item?.subtitle ?? '')
    setDescription(item?.description ?? '')
    setAmount(item?.amount ?? 0)
    setImage(item?.image ?? '')
    setLink(item?.link ?? '')
  }, [item])

  const submit = (ev) => {
    ev.preventDefault();

    setState('posting');

    EditItemApi({
      id: item?.id,
      title: title,
      subtitle: subtitle,
      description: description,
      amount: amount,
      link: link
    }, image)
      .then(r => {
        if (!r.error) {
          setState('posted');
          fetchItems();
          navigate('../')
          return;
        }

        setState('error')
        setError(r)
      })
      .catch(e => {
        console.error('failed to add', e)
        setState('error')
        setError(e)
      })
  }

  return (
    <>
      <p>Edit {item?.id ?? ''}</p>
      {
        {
          'posted': <p>Success! Reloading...</p>,
          'error': <div>
            <p>Failed :(</p>
            <p>{JSON.stringify(error)}</p>
          </div>
        }[state]
      }
      <form onSubmit={submit}>
        <TextInput value={title} onChange={(e) => setTitle(e.target.value)} name='title' label='Title' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <TextInput value={subtitle} onChange={(e) => setSubtitle(e.target.value)} id='subTitle' label='Subtitle' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <TextInput value={description} onChange={(e) => setDescription(e.target.value)} id='description' disabled={state === 'posted' || state === 'posting'}
                   label='Description' className='my-1'/>
        <TextInput value={amount} onChange={(e) => setAmount(e.target.value)} type='number' id='amount' disabled={state === 'posted' || state === 'posting'}
                   label='Amount ($)' className='my-1'/>
        <TextInput value={link} onChange={(e) => setLink(e.target.value)} id='link' label='Link' disabled={state === 'posted' || state === 'posting'}
                   className='my-1'/>
        <div className='my-1'>
          <p className='text-sm block text-gray-700'>Image</p>
          <p className='text-base'>{item?.image}</p>
          <ItemImage src={`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/${item?.image}`} alt='item' />
          <input type='file' onChange={(e) => setImage(e.target.files[0])}/>
        </div>
        <Button type='submit' className='py-2 px-12 my-4 m-auto' disabled={state === 'posted' || state === 'posting'}>Save</Button>
      </form>
    </>)
}

export default EditItem;