import {useEffect, useState} from "react";
import PlaceholderImage from "../../../assets/our story/210321_Arend_and_Michelle_1491_WebRes.jpg";
import {Link} from "react-router-dom";
import Button from "../../../components/Button/Button";

export const ItemImage = ({src, alt}) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (<div className='w-full h-72 overflow-hidden relative'>
    <img src={imgSrc} alt={alt} className='object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0'
         onError={() => {
           console.warn('Missing image', src)
           setImgSrc(PlaceholderImage)
         }}/>
  </div>)
}

export const IndivItem = ({item}) => {
  return (
    <div className='p-8 h-full'>
      <Link to={`${item.id}/details`} className='block group relative flex flex-col h-full'>
        <ItemImage src={`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/${item.image}`} alt={item.title}/>

        <div className='my-2 pt-2 w-full flex-grow'>
          <h3 className='text-xl leading-6'>{item.title}</h3>
          <p className='text-base'>{item.subtitle}</p>
          <p className='text-base font-sans'>${item.amount}</p>
        </div>
        <div className='pt-2 w-full'>
          {item.reserved ?
            <Button className='w-full p-2'>Reserved</Button>
            : <Button
              className='w-full p-2 bg-marriage-blue group-hover:bg-white text-white group-hover:text-marriage-blue'>Available</Button>
          }
        </div>
      </Link>
    </div>
  )
}