import {Link} from "react-router-dom";
import Button from "../../../components/Button/Button";
import {IndivItem, ItemImage} from "./IndivItem";

const FundsGiftLink = () => <div className='p-8 h-full'><Link to='transfer'
                                                              className='block group h-full flex flex-col'>
  <ItemImage src={`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/funds_image.jpg`} alt='New home render'/>
  <div className='my-2 pt-2 w-full flex-grow'>
    <h3 className='text-xl leading-6'>New Home Funds</h3>
    <p className='text-base font-sans'>Contribute what you wish</p>
  </div>
  <div className='pt-2 w-full'>
    <Button className='w-full p-2 bg-marriage-blue group-hover:bg-white text-white group-hover:text-marriage-blue'>
      Show me how
    </Button>
  </div>
</Link>
</div>

const CharityLink = () => <div className='p-8 h-full'><Link to='charity' className='block group h-full flex flex-col'>
  <ItemImage src={`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/charity.png`} alt='Pugs'/>
  <div className='my-2 pt-2 w-full flex-grow'>
    <h3 className='text-xl leading-6'>Donation to Pug Rescue Victoria</h3>
    <p className='text-base font-sans'>Contribute what you wish</p>
  </div>
  <div className='pt-2 w-full'>
    <Button className='w-full p-2 bg-marriage-blue group-hover:bg-white text-white group-hover:text-marriage-blue'>
      Show me how
    </Button>
  </div>
</Link></div>

const ItemList = ({registryState, items}) => {
  return (
    <section className='bg-white w-full'>
      {
        {
          'loading': <p className='text-center py-12'>Loading...</p>,
          'error': <p className='text-center py-12'>Sorry, something went wrong :(</p>,
        }[registryState] ||
        <>
          <div className='m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-12 max-w-[1800px]'>
            <FundsGiftLink/>
            <CharityLink/>
            {items.map(item => <IndivItem key={item.id} item={item}/>)}
          </div>
          <div className='text-center px-12 pb-12'>
            <p className='text-xl'>If you don't see an item you like, check back later! We will add more items in the
              lead up to the wedding.</p>
          </div>
        </>
      }
    </section>
  )
}

export default ItemList;