import ItemList from "./ItemList/ItemList";
import {Routes, Route, Outlet, Link} from "react-router-dom";
import ItemDetails from "./ItemDetails/ItemDetails";
import TransferGuide from "./Transfer/TransferGuide";
import HeaderImage from '../../assets/registry/registry_top.png'
import {useEffect, useState} from "react";
import { FetchItems } from "../../services/RegistryService/RegistryService";
import ItemManagement from "./ItemManagement/ItemManagement";
import ItemLayout from "./ItemDetails/ItemLayout";
import WebLink from "../../components/Link/WebLink";

const Registry = () => {
  const [state, setState] = useState('loading');
  const [items, setItems] = useState([])

  const fetchItems = () => {
    setState('loading');
    FetchItems()
      .then(r => {
        if (r.error) {
          setState('error')
          return;
        }

        setState('ready');
        setItems(r);
      })
      .catch(e => {
        console.error('failed to fetch items', e)
        setState('error')
      })
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<ItemList items={items} registryState={state} />}/>
          <Route path=':id/details' element={<ItemDetails items={items} registryState={state} />}/>
          <Route path='transfer' element={<TransferGuide />}/>
          <Route path='charity' element={<CharityGuide />}/>
          <Route path='management/*' element={<ItemManagement items={items} fetchItems={fetchItems} registryState={state} />} />
        </Route>
      </Routes>
    </>
  )
}

const Layout = () => {
  return (
    <>
      <section
        style={{backgroundImage: `url(${HeaderImage}`, backgroundSize: 'cover'}}
        className='bg-gray-700 h-[85vh] w-full bg-center flex flex-col px-16 justify-center items-center text-center '>
        <div className='max-w-5xl'>
          <h1 className='text-white text-3xl md:text-4xl'>Many of you are traveling across the globe to celebrate with us in Melbourne, so your presence means the world to us and is all we ask for.</h1>
          <h2 className='text-white text-xl md:text-2xl mt-8'>If you'd still like to contribute to our registry or home funds, please see below for some ideas.</h2>
        </div>
        {/*You are under no obligation to give. <nobr>Only if you want to, and are able to.*/}
        {/*If you'd still like to contribute to our registry or home funds, please see below for some ideas or*/}
      </section>
      <Outlet/>
    </>)
}

const CharityGuide = () => {
  return <section className='bg-white w-full'>
    <div className='m-auto max-w-[1200px] px-12 py-4'>
      <Link to='/registry'
            className='underline hover:text-marriage-blue'>Registry</Link> &gt; Charity
    </div>
    <div className='m-auto max-w-[1200px] px-12 pb-8'>
      <ItemLayout image='charity.png' alt='Pugs'>
        <h2 className='text-2xl'>Donation to Pug Rescue Victoria</h2>
        <p className='text-base'></p>
        {/*<p className='text-base font-sans'>Any amount!</p>*/}
        <p className='text-base pt-4'>We are more than happy if you wish to donate to pugs in need!</p>
        <p className='text-base pb-2'>Follow instructions on how to donate on the Pug Rescue Victoria website. Any amount would be very appreciated.</p>
        <p className='text-base pt-4'><WebLink href='http://www.pugrescue.org.au/donations/' isExternal>http://www.pugrescue.org.au/donations</WebLink></p>
      </ItemLayout>
    </div>
  </section>
}

export default Registry;