import {RemoveItem as RemoveItemApi} from "../../../services/RegistryService/RegistryService";
import LoginModule from "./LoginModule";
import AddItem from "./AddItem";
import { Routes, Route, Outlet } from "react-router-dom";
import ManagementList from "./ManagementList";
import EditItem from "./EditItem";

const ItemManagement = ({items, fetchItems, registryState}) => {
  const removeItem = (id) => {
    RemoveItemApi(id)
      .then(r => {
        if (!r.error) {
          fetchItems();
          return;
        }

        console.error('failed to delete', r)
      })
      .catch(e => console.error('failed to delete', e))
  }

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<ManagementList items={items} removeItem={removeItem} />} />
        <Route path='add' element={<AddItem fetchItems={fetchItems} />} />
        <Route path='edit/:id' element={<EditItem fetchItems={fetchItems} items={items} />} />
      </Route>
    </Routes>
  )
}

const Layout = ({registryState}) => {
  return (
    <section className='bg-white w-full p-8'>
      <div className='max-w-5xl m-auto'>
        <LoginModule />
        {registryState === 'error' ? <p className='text-center'>Something went wrong :(</p> : undefined}
        {registryState === 'loading' ? <p className='text-center'>Loading...</p> : undefined }
      </div>
      <Outlet />
    </section>
  )
}

export default ItemManagement;