const baseUrl = process.env.REACT_APP_API_HOST !== undefined ? process.env.REACT_APP_API_HOST : '';

const createFriendlyApiError = (error, status, details) => [true, {
  error,
  status,
  details
}]

const tryHandleError = async (response) => {
  if (response.status === 400) {
    return createFriendlyApiError('validation-error', response.status, await response.json())
  }

  if (response.status >= 400 && response.status < 600) {
    try {
      const result = await response.json()
      return createFriendlyApiError('bad-status', response.status, result)
    } catch {
      return createFriendlyApiError('bad-status', response.status)
    }
  }

  if (!response.ok) {
    return createFriendlyApiError('connection-failed');
  }

  return [false, null]
}

const wrappedFetch = async (url, init) => {
  let res;
  try {
    res = await fetch(url, init);
  }
  catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(res);
  if (errored) {
    return error
  }

  return {}
}

const wrappedFetchWithResponse = async (url, init) => {
  let response;
  try {
    response = await fetch(url, init)
  } catch
    (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  try {
    return await response.json();
  } catch (e) {
    console.error('failed to read json response', e)
    const [, error] = createFriendlyApiError('json-read-failure', response.status, await response.text());
    return error;
  }
}

export { baseUrl, tryHandleError, createFriendlyApiError, wrappedFetch, wrappedFetchWithResponse }