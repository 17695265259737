import { createFriendlyApiError, tryHandleError} from "../Shared/Common";

const GetAuth = async () => {

  let res;
  try {
    res = await fetch(`/.auth/me`);
  }
  catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(res);
  if (errored) {
    return error
  }

  try {
    return await res.json();
  } catch (e) {
    console.error('failed to read json response', e)
    const [, error] = createFriendlyApiError('json-read-failure', res.status, await res.text());
    return error;
  }
}

export default GetAuth;