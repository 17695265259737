import BannerImage from '../../../assets/rsvp/rsvp_background.png'
import {useState} from "react";
import RadioInput from '../../../components/RadioInput/RadioInput';
import TextInput from '../../../components/TextInput/TextInput';
import AdditionalGuests from "./AdditionalGuests";
import {SubmitRsvp} from "../../../services/RsvpService/RsvpService";
import Button from "../../../components/Button/Button";
import {NavLink} from "react-router-dom";


const RsvpForm = () => {
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setNameValid(true);
    setEmailValid(true);
    setSubmitResult({
      Status: null,
      Message: null,
      Errors: []
    });

    if (form.firstName === '') {
      setNameValid(false);
    }
    if (form.email === '') {
      setEmailValid(false);
    }
    if (form.attending === null) {
      setAttendingValid(false);
    }

    if (form.firstName === '' || form.email === '' || form.attending === null) return;

    setSubmitting(true);
    const res = await SubmitRsvp(form);
    setSubmitting(false);
    if (!res.error) {
      setSubmitResult({
        Status: 'ok'
      })
      return;
    }

    if (res.error === 'validation-error') {
      setSubmitResult({
        Status: res.error,
        Errors: res.details.errors,
        Message: 'Sorry, something has gone wrong'
      })
      res.details.errors.forEach(e => {
        switch (e.propertyName) {
          case 'Attending':
            setAttendingValid(false);
            break;
          case 'FirstName':
            setNameValid(false);
            break;
          case 'Email':
            setEmailValid(false);
            break;
          default:
            break;
        }
      })
    } else {
      setSubmitResult({
        Status: res.error,
        Message: 'Sorry, something has gone wrong. Try again?'
      })
    }
  }

  const [attendingValid, setAttendingValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    attending: null,
    additional: [{
      id: 0,
      name: ''
    }],
    dietary: '',
    comments: ''
  })
  const [submitting, setSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState({
    Status: 'unsubmitted',
    Errors: [],
    Message: null
  })
  const setFirstName = (ev) => {
    setForm({...form, firstName: ev.target.value})
    let valid = true;
    if (ev.target.value === '') {
      valid = false;
    }
    setNameValid(valid);
  }
  const setEmail = (ev) => {
    setForm({...form, email: ev.target.value})
    let valid = true;
    if (ev.target.value === '') {
      valid = false;
    }
    setEmailValid(valid);
  }
  const setAttending = (val) => {
    setAttendingValid(true);
    setForm({...form, attending: val})
  }

  return (
    <>
      <section
        style={{backgroundImage: `url(${BannerImage})`, backgroundSize: "cover"}}
        className='text-white bg-center min-h-[66vh] pt-32 flex items-center justify-center text-center'>
        <div className='py-[6.6vmax]'>
          <h1 className='text-white text-7xl py-6'>RSVP</h1>
          <p>We look forward to seeing you!</p>
        </div>
      </section>
      <section className='bg-white text-marriage-blue p-[4vw] w-full'>
        <div className='w-[90%] lg:w-1/2 m-auto'>
          <div className='text-center pb-[4vw]'>
            <h2 className='text-4xl'>Michelle and Arend's Wedding</h2>
            <p className='text-lg py-4'>Please RSVP by <nobr>25th October 2022</nobr></p>
            <p className='text-lg py-4'>By RSVP-ing, you are confirming you will attend both the ceremony and the dinner
              so that we can book seats for you. If you would like to come to only one of the events, please let us know
              in the comment box.</p>
            <p className='text-lg py-4'>No dress code, wear what makes you feel happy!</p>
          </div>
          {submitResult.Status === 'ok' ?
            <>
              <p className='text-center'>
                Thank You!
              </p>
              <p className='text-center text-lg'>
                For those who would like to purchase a wedding gift, we have a <NavLink to='/registry' className='underline decoration-1 underline-offset-2 hover:text-marriage-blue'>gift registry</NavLink>.
              </p>
            </> :
            <form className='w-full font-sans' onSubmit={handleSubmit}>
              <div className='w-full grid grid-cols-6 gap-y-8 gap-x-4'>
                <TextInput name='first-name' label='First Name *' placeholder='eg. Arend' value={form.firstName}
                           onChange={setFirstName}
                           className='col-span-6 sm:col-span-3'
                           valid={nameValid}/>
                <TextInput name='last-name' label='Last Name' placeholder='eg. Oppewal' value={form.lastName}
                           onChange={(ev) => setForm({...form, lastName: ev.target.value})}
                           className='col-span-6 sm:col-span-3'/>
                <TextInput name='email' label='Email *' placeholder='eg. email@gmail.com' value={form.email}
                           type='email'
                           onChange={setEmail}
                           className='col-span-6'
                           valid={emailValid}/>
                <div className='col-span-6'>
                  <fieldset>
                    <p className={`text-sm mb-1 ${!attendingValid ? 'text-red-700' : undefined}`}>Will you be attending?
                      *</p>
                    <RadioInput name='attending' value='yes' label='Yes' checked={form.attending === true}
                                onChange={() => setAttending(true)}
                                valid={attendingValid}/>
                    <RadioInput name='attending' value='no' label='No' checked={form.attending === false}
                                onChange={() => setAttending(false)}
                                valid={attendingValid}/>
                  </fieldset>
                </div>
                {!form.attending ? undefined :
                  <>
                    <AdditionalGuests form={form} setForm={setForm}/>
                    <TextInput name='dietary' label='Any food restrictions? (optional)'
                               placeholder='eg. Vegetarian, Vegan, Allergies'
                               value={form.dietary} onChange={(ev) => setForm({...form, dietary: ev.target.value})}
                               className='col-span-6'/>
                  </>
                }
                <TextInput name='comments' label='Questions or Comments (optional)' placeholder='' value={form.comments}
                           onChange={(ev) => setForm({...form, comments: ev.target.value})} className='col-span-6'/>
                <div className='col-span-6 text-center'>
                  <p>
                    {submitResult.Message}
                  </p>
                  {submitResult.Errors ? submitResult.Errors.map(a => <p
                    className='text-sm m-2'>{a.errorMessage}</p>) : undefined}
                </div>
                <div className='col-span-6 text-center'>
                  <SubmitButton submitting={submitting}/>
                </div>
              </div>
            </form>
          }
        </div>
      </section>
    </>
  )
}

const SubmitButton = ({submitting}) => {
  return <Button className='p-4 w-1/3' disabled={submitting} type='submit'>
    {submitting ?
      <>Submitting...</> :
      <>Submit</>
    }
  </Button>
}

export default RsvpForm;