import {Link, useParams} from 'react-router-dom'
import {useState} from "react";
import {ReserveItem, UnreserveItem} from "../../../services/RegistryService/RegistryService";
import WebLink from "../../../components/Link/WebLink";
import {Form} from "./Form";
import ItemLayout from "./ItemLayout";

const ItemDetails = ({items, registryState}) => {
  var {id} = useParams();

  let item = null;
  const filteredItems = items.filter(i => i.id === id);
  if (items.length !== 0) {
    item = filteredItems[0]
  }

  return (<section className='bg-white w-full'>
    <div className='m-auto max-w-[1200px] px-12 py-4'>
      <Link to='/registry'
            className='underline hover:text-marriage-blue'>Registry</Link> &gt; Details
    </div>
    <div className='m-auto max-w-[1200px] px-12 pb-8'>
      {
        {
          'loading': <p className='text-center my-12'>Loading...</p>,
          'error': <p className='text-center my-12'>Sorry, something went wrong :(</p>,
        }[registryState] || ((item === null || item === undefined) ?
            <MissingItem/> : <Item item={item}/>
        )
      }
    </div>
  </section>)
}

const Item = ({item}) => {
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState(item.reserved ? 'reserved' : 'available');
  const [message, setMessage] = useState(item.reserved ? 'reserved' : '');
  const [loading, setLoading] = useState(false)

  const reserve = (ev) => {
    ev.preventDefault();

    setLoading(true)
    setMessage('')

    ReserveItem(item.id, email)
      .then(r => {
        setLoading(false);
        if (!r.error) {
          setFormState('submitted')
          return;
        }

        setMessage(r.details.error ?? r.error);
      })
      .catch(e => {
        console.error('failed to reserve', e)
        setLoading(false);
      })
  }

  const unreserve = (ev) => {
    ev.preventDefault();

    setLoading(true)
    setMessage('')

    UnreserveItem(item.id, email)
      .then(r => {
        setLoading(false);
        if (!r.error) {
          setFormState('unreserved')
          setMessage('unreserved')
          return;
        }

        setMessage(r.details.error ?? r.error);
      })
      .catch(e => {
        console.error('failed to unreserve', e)
        setLoading(false);
      })
  }

  return (
    <ItemLayout image={item.image} alt={item.title}>
      <h2 className='text-2xl'>{item.title}</h2>
      <p className='text-base'>{item.subtitle}</p>
      <p className='text-base font-sans'>${item.amount}</p>
      <p className='text-base pt-4'>{item.description}</p>
      <p className='text-base pt-4'><WebLink href={item.link} isExternal={true}>{item.link}</WebLink></p>
      <div className='mt-8'>
        {
          {
            'reserved': <div>
              <p className='text-base mb-2'>Sorry, this has already been reserved</p>
              <p className='text-base'>If you reserved this item and would like to remove your reservation, please
                enter the email address you reserved with.</p>
            </div>,
            'bad-email': <div>
              <p className='text-base mb-2'>Sorry, that is the wrong email address.</p>
              <p className='text-base'>Please let us know if you need help unreserving!</p>
            </div>,
            'submitted': <Submitted/>,
            'unreserved': <div>
              <p className='text-base'>Thank you for giving someone else the opportunity to reserve.</p>
            </div>,
            'not-reserved': <div>
              <p className='text-base'>This item has not been reserved. Please refresh the page.</p>
            </div>,
            'invalid': <div>
              <p className='text-base'>Please enter a valid email address.</p>
            </div>,
            'not-found': <div>
              <p className='text-base'>Sorry, something went wrong. Please refresh the page.</p>
            </div>,
            'validation-error': <div>
              <p className='text-base'>Please enter a valid email address.</p>
            </div>
          }[message]
        }
        {
          {
            'available': <Form submit={reserve} email={email} setEmail={setEmail} buttonContent='Reserve'
                               loading={loading}/>,
            'reserved': <Form submit={unreserve} email={email} setEmail={setEmail} buttonContent='Unreserve'
                              loading={loading}/>,
            'submitted': <Submitted submit={unreserve} email={email} setEmail={setEmail} loading={loading}/>,
            'unreserved': <></>
          }[formState]
        }
      </div>
    </ItemLayout>)
}

const MissingItem = () => <p className='text-center'>Sorry, that item doesn't exist</p>

const Submitted = ({submit, email, setEmail, loading}) => <div className='mt-8'>
  <p className='mt-8 text-base'>Thank you! If you change your mind, enter the same address you used to reserve the item
    with to
    remove yourself from the reservation.</p>
  <Form submit={submit} email={email} setEmail={setEmail} buttonContent='Unreserve'
        loading={loading}/>
</div>

export default ItemDetails;