import React from "react";
import GetAuth from "../services/AuthService/AuthService";


// Example API response - logged in
// {
//   "clientPrincipal": {
//     "identityProvider": "<provider>",
//     "userId": "<alpahnum>",
//     "userDetails": "<email/username>",
//     "userRoles": [
//       "<role1>",
//       "<role2>",
//       "<role3>"
//     ]
//   }
// }

// Example API response - logged out
// {
//   "clientPrincipal": null
// }

const AuthStateContext = React.createContext();

export const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined)
    throw new Error("useAuthState must be used with an AuthProvider")

  return context;
}

export const AuthProvider = ({children}) => {
  const [state, setState] = React.useState({
    principle: null,
    loggedIn: false,
    loading: true
  });

  React.useEffect(() => {
    GetAuth()
      .then(r => {
        if (r.error) {
          setState({
            principle: null,
            loggedIn: false,
            loading: false
          });

          console.error('failed to get auth', r)
          return;
        }

        setState({
          principle: r.clientPrincipal,
          loggedIn: !!r.clientPrincipal,
          loading: false
        });
      })
      .catch(e => {
        setState({
          principle: null,
          loggedIn: false,
          loading: false
        });
        console.error('failed to get auth', e)
      })
  }, [])

  return (
    <AuthStateContext.Provider value={state}>
        {children}
    </AuthStateContext.Provider>
  )
}