import { createFriendlyApiError, tryHandleError, wrappedFetchWithResponse } from "../Shared/Common";

const SubmitRsvp = async (rsvpRequest) => {
  const data = {
    FirstName: rsvpRequest.firstName,
    LastName: rsvpRequest.lastName,
    Email: rsvpRequest.email,
    Attending: rsvpRequest.attending,
    DietaryRequirements: rsvpRequest.dietary,
    Comments: rsvpRequest.comments,
    AdditionalGuests: rsvpRequest.additional.map(a => {
      return {Name: a.name}
    }),
    Password: rsvpRequest.a_password,
  }

  let res;
  try {
    res = await fetch(`/api/rsvp`, {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });
  }
  catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(res);
  if (errored) {
    return error
  }

  return {}
}

const FetchGuests = async () => {
  return await wrappedFetchWithResponse('/api/rsvp/guests');
}

export { SubmitRsvp, FetchGuests };