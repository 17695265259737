import {useState} from "react";
import {TextInput} from "../../../components/TextInput/TextInput";

export const AdditionalGuests = ({form, setForm}) => {
  const [nextGuestId, setNextGuestId] = useState(1)

  const addGuest = () => {
    const emptyGuest = {
      id: nextGuestId,
      name: ''
    };
    setForm({
      ...form,
      additional: [...form.additional, emptyGuest]
    })
    setNextGuestId(nextGuestId + 1)
  }
  const removeGuest = (id) => {
    setForm({
      ...form,
      additional: form.additional.filter((v) => v.id !== id)
    })
  }
  const changeHandler = (ev) => {
    const updatedGuest = form.additional.find(g => `additional-name-${g.id}` === ev.target.name)
    updatedGuest.name = ev.target.value;

    const guests = [updatedGuest, ...form.additional.filter(g => `additional-name-${g.id}` !== ev.target.name)]

    setForm({
      ...form,
      additional: guests.sort((a, b) => {
        return a.id - b.id;
      })
    })
  }

  return (
    <>
      <div className='col-span-6 grid grid-cols-6 gap-4'>
        {form.additional.map((a, i) =>
          <div key={a.id} className='col-span-6 flex flex-row flex-wrap items-end gap-x-4 md:gap-y-0'>
            <TextInput className='flex-grow' name={`additional-name-${a.id}`} value={a.name}
                       label='Additional Guest (leave blank if no additional guests)'
                       placeholder='Name'
                       onChange={changeHandler}/>
            {i === 0 && form.additional.length === 1 ? undefined :
              <RemoveGuestButton removeGuest={removeGuest} guest={a}/>}
          </div>
        )}
        <div className='col-span-6 text-sm mt-2'>
          <AddGuestButton addGuest={addGuest}/>
        </div>
      </div>
    </>
  )
}

const AddGuestButton = ({addGuest}) =>
  <button type='button' onClick={addGuest}
          className='border rounded-sm p-2 flex items-center hover:border-marriage-blue'>
    <div className='inline-flex'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className='stroke-marriage-blue w-5 h-5'
           stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
      </svg>
    </div>
    <span className='pl-2'>Add another guest</span>
  </button>

const RemoveGuestButton = ({guest, removeGuest}) =>
  <button type='button' onClick={() => removeGuest(guest.id)}
          className='border rounded-sm w-[42px] h-[42px] p-2 hover:border-marriage-blue shadow-sm'>
    <span className='sr-only'>Remove guest</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         className='stroke-marriage-blue'
         stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className=''
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
    </svg>
  </button>


export default AdditionalGuests;