import Nav from "./components/Header/Nav";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import Registry from "./views/Registry/Registry";
import RsvpForm from "./views/Rsvp/RsvpForm/RsvpForm";
import RsvpList from "./views/Rsvp/RsvpList/RsvpList";
import Story from "./views/Story/Story";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet, useLocation, useNavigationType
} from "react-router-dom";
import React, {useEffect} from "react";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path='story' element={<Story/>}/>
              <Route path='registry/*' element={<Registry/>}/>
              <Route path='rsvp' element={<RsvpForm/>}/>
              <Route path='rsvp/list' element={<RsvpList />} />
              <Route path='recording' element={<Recording />} />
              <Route path='*' element={<NoMatch/>}/>
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <>
      <Nav/>
      <Outlet/>
      <Footer/>
    </>
  )
}

function NoMatch() {
  return (
    <div className='bg-[#e0e0db] w-screen h-[66vh] flex flex-col justify-center items-center'>
      <h1 className='text-6xl py-6'>Nothing here!</h1>
      <p className='underline hover:text-white'><a href='/'>Go home?</a></p>
    </div>
  )
}

function Recording() {
  return (
    <section className='mt-32 justify-center items-center text-center'>
      <div>
      <h1 className='text-6xl py-6'>The recording is not yet available.</h1>
      <p>Sorry, please check back later!</p>
      <p className='underline hover:text-white'><a href='/'>Go home?</a></p>
      </div>
    </section>
  )
}

const ScrollToTop = (props) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'POP' && !location.pathname.startsWith('/registry/')) {
      window.scrollTo(0, 0);
    }
  }, [location, navigationType]);

  return <>{props.children}</>
};

export default App;
