import {useAuthState} from "../../../contexts/AuthContext";
import BannerImage from '../../../assets/rsvp/rsvp_background.png'
import {FetchGuests} from "../../../services/RsvpService/RsvpService";
import {useEffect, useState} from "react";

const Loading = () => <h1>Loading...</h1>

const RsvpList = () => {
  const authState = useAuthState();

  let state = authState.loading ? 'loading' : 'unauthenticated';
  if (!authState.loading && authState.loggedIn) {
    state = 'authenticated';
  }

  return <>
    <section
      style={{backgroundImage: `url(${BannerImage})`, backgroundSize: "cover"}}
      className='text-white bg-center min-h-[66vh] pt-32 flex items-center justify-center text-center'>
      <div className='py-[6.6vmax]'>
        <h1 className='text-white text-7xl py-6'>RSVP</h1>
        <p>We look forward to seeing you!</p>
      </div>
    </section>
    <section className='m-auto'>
      {
        {
          'loading': <Loading/>,
          'unauthenticated': <p><a href='/login'>Login</a></p>,
          'authenticated': <List/>
        }[state]
      }
    </section>
  </>
}

const Guest = ({guest}) => {
  const name = guest.name ? guest.name : (guest.firstName + ' ' + guest.lastName);

  let style = 'border-marriage-blue';
  switch (guest.type) {
    case 'root':
      style += ' border-t border-x';
      break;
    case 'single':
      style += ' border-y border-x';
      break;
    case 'last':
      style += ' border-b border-x';
      break;
    case 'standard':
      style += ' border-x';
      break;
    default:
      style = '';
      break;
  }

  return <tr className={style}>
    <td className='py-1 px-2'>{guest.rsvpId}</td>
    <td className='py-1 px-2'>{name}</td>
    <td className='py-1 px-2'>{guest.email}</td>
    <td className='py-1 px-2'>{guest.attending === undefined ? '' : guest.attending ? 'Attending' : 'Absent'}</td>
    <td className='py-1 px-2'>{guest.dietary}</td>
    <td className='py-1 px-2'>{guest.comments}</td>
  </tr>
}

const RsvpResponse = ({rsvp}) => {
  const rootGuest = {
    ...rsvp,
    type: rsvp.additional.length === 0 ? 'single' : 'root'
  };
  rootGuest.rsvpId = rsvp.id

  return <>
    <Guest guest={rootGuest}/>

    {
      rsvp.additional.map((g, i) => {
        const guest = {
          rsvpId: rsvp.id,
          name: g.name,
          type: i === rsvp.additional.length -1 ? 'last' : 'standard'
        }

        return <Guest guest={guest}/>
      })
    }
  </>
}

const List = () => {
  const [state, setState] = useState('loading');
  const [rsvps, setRsvps] = useState([]);

  const fetchRsvps = () => {
    setState('loading');
    FetchGuests()
      .then(r => {
        if (r.error) {
          setState('error');
          return;
        }

        setState('ready');
        setRsvps(r.rsvps);
      })
      .catch(e => {
        console.error('failed to fetch rsvps', e)
        setState('error')
      })
  }
  console.log('rsvps', rsvps)

  useEffect(() => {
    fetchRsvps();
  }, []);

  return <>
    {state === 'loading' ? <Loading/> : undefined}
    <table className='m-auto'>
      <thead>
      <tr>
        <th>Rsvp ID</th>
        <th>Name</th>
        <th>Email</th>
        <th>Attending</th>
        <th>Dietary</th>
        <th>Comments</th>
      </tr>
      </thead>
      <tbody>
      {rsvps.map(r => {
        return <RsvpResponse key={r.id + r.firstName + r.lastName} rsvp={r}/>
      })}
      </tbody>
    </table>
  </>
}

export default RsvpList