import WebLink from "../../../components/Link/WebLink";
import Button from "../../../components/Button/Button";
import {useState} from "react";

const DeleteButton = ({removeItem}) => {
  const [conf, setConf] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const clickHandler = (e) => {
    e.preventDefault();
    if (conf) {
      setDeleting(true);
      removeItem()
    }

    setConf(true);

    setTimeout(() => setConf(false), 2000);
  }

  return (
    <Button onClick={clickHandler} className='p-2 mt-2 hover:bg-red-800 hover:border-red-900'
            disabled={deleting}>
      {conf ? 'Are you sure?' : (deleting ? 'Deleting...' : 'Delete') }
    </Button>)
}

const ManagementList = ({items, removeItem}) => {
  return (
    <div className='max-w-5xl m-auto'>
      <h2 className='text-2xl'>Management</h2>
      {items.map(i => <div key={i.id} className='my-4 flex flex-row w-full gap-4 pb-4 border-b-2 last:border-b-0'>
        <img src={`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/${i.image}`} alt={i.title} className='w-40 h-fit'/>
        <div className='flex-1'>
          <p className='text-base'>Id: <WebLink href={`/registry/management/edit/${i.id}`} isExternal={false}>{i.id}</WebLink></p>
          <p className='text-base'>Title: {i.title}</p>
          <p className='text-base'>Subtitle: {i.subtitle}</p>
          <p className='text-base'>Description: {i.description}</p>
          <p className='text-base'>Reserved: {i.reserved} {i.user === undefined ? undefined : `(${i.user})`}</p>
          <p className='text-base'>Amount: ${i.amount}</p>
          <p className='text-base'>Image: {i.image}</p>
          <p className='text-base'>Link: <WebLink href={i.link} isExternal={true}>{i.link}</WebLink></p>
          <DeleteButton removeItem={() => removeItem(i.id)} />
        </div>
      </div>)}
    </div>
  )
}
export default ManagementList;