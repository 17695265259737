import HeroImage from '../../assets/home/top.jpg'
import MassImage from '../../assets/home/ceremony_image.jpg'
import DinnerImage from '../../assets/home/dinner_image.jpg'
// import RsvpImage from '../../assets/rsvp/rsvp_background.png'
import {NavLink} from "react-router-dom";
import WebLink from "../../components/Link/WebLink";

function HomeApp() {
  return (
    <main>
      <section
        style={{backgroundImage: `url(${HeroImage})`, backgroundSize: "cover"}}
        className='h-screen bg-marriage-blue w-full bg-top text-white flex justify-center items-center text-center'>
        <div>
          {/* Redundant font-garamond here to stop tailwind from removing it for optimized bundle */}
          <p className='font-garamond'>We're getting married... again!</p>
          <h1 className='text-7xl py-6'>Michelle & Arend</h1>
          <p>10<sup>th</sup> December 2022</p>
        </div>
      </section>
      <section className='bg-[#e0e0db] w-full max-w-[1800px] m-auto text-center'>
        <div className='flex flex-col md:flex-row items-center justify-around gap-6 px-[4vw] py-[6vw]'>
          <img src={MassImage} alt="From Michelle and Arend's lockdown ceremony - Outside Parliament"
               className='w-full max-h-screen md:max-w-[45%] object-contain flex-initial'/>
          <div className=''>
            <h1 className='text-6xl py-6'>Nuptial Mass</h1>
            <h2 className='text-3xl py-6'>2:00 PM</h2>
            <h3 className='text-2xl py-3 font-bold'>Sts Peter and Paul Church</h3>
            <p className='py-1'><WebLink href="https://goo.gl/maps/tN5vQAETL7CknR1Z8" isExternal={true}>377 Dorcas St, South Melbourne VIC 3205</WebLink></p>
          </div>
        </div>
      </section>
      <section className='bg-[#e0e0db] w-full max-w-[1800px] px-[4vw] py-[4vw] m-auto text-center'>
        <div className='flex flex-col flex-col-reverse md:flex-row items-center justify-around gap-6'>
          <div>
            <h1 className='text-6xl py-6'>Dinner</h1>
            <h2 className='text-3xl py-6'>6:00 PM</h2>
            <h3 className='text-2xl py-3 font-bold'>Makan Restaurant</h3>
            <p className='py-1'><WebLink href="https://goo.gl/maps/ssuLAVjwamBe9TWMA" isExternal={true}>360 Collins St, Melbourne VIC 3000 <br/>
              Enter through Collins Way via Little Collins St</WebLink></p>
          </div>
          <img src={DinnerImage}
               alt="From Michelle and Arend's lockdown ceremony - Outside Parliament with silly face"
               className='w-full max-h-screen md:max-w-[45%] object-contain flex-initial'/>
        </div>
      </section>
      <section
        className='bg-[#e0e0db] h-[80vh] max-h-[600px] w-full px-[4vw] py-[4vw] text-center flex justify-between'>
        <div className='hidden lg:flex flex-grow items-center px-20'>
          <hr className='hr flex-grow border-[#253551]'/>
        </div>
        <div className="flex flex-grow lg:flex-initial items-center">
          <div className='m-auto'>
            <h1 className='text-5xl py-6 text-[#253551]'>See how it all started</h1>
            <div>
              <NavLink to='/story'
                       className='inline-block bg-[#253551] px-8 py-4 text-white text-xl transition hover:bg-white hover:text-marriage-blue'>Our Story</NavLink>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex flex-grow items-center px-20'>
          <hr className='hr flex-grow border-[#253551]'/>
        </div>
      </section>
      {/*<section className={`bg-[#e0e0db] h-screen px-[4vw] py-[4vh]`}>*/}
      {/*  <div*/}
      {/*    style={{backgroundImage: `url(${RsvpImage})`, backgroundSize: "cover", backgroundPosition: 'center center' }}*/}
      {/*    className='text-center text-white items-center flex justify-center flex-col relative h-full'>*/}
      {/*    <p>Join Us</p>*/}
      {/*    <h1 className='text-6xl py-8'>We hope you can make it!</h1>*/}
      {/*    <NavLink to='/rsvp'*/}
      {/*             className='inline-block bg-white text-lg text-black py-4 px-6 mt-4 transition hover:bg-marriage-blue hover:text-white'>RSVP</NavLink>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </main>
  )
}

export default HomeApp