import {useState} from "react";
import {Link} from "react-router-dom";
import {Form} from "../ItemDetails/Form";
import {TransferGuide as TransferGuideAPI} from "../../../services/RegistryService/RegistryService";
import ItemLayout from "../ItemDetails/ItemLayout";

const TransferGuide = () => {
  const [email, setEmail] = useState('')
  const [state, setState] = useState('');

  const submit = (e) => {
    e.preventDefault()

    setState('loading')
    TransferGuideAPI(email)
      .then(r => {
        if (!r.error) {
          setState('submitted');
          return;
        }

        setState('error');
      })
      .catch(e => {
        console.error('failed to trigger email')
        setState('error');
      })
  }

  return (
    <section className='bg-white w-full'>
      <div className='m-auto max-w-[1200px] px-12 py-4'>
        <Link to='/registry'
              className='underline hover:text-marriage-blue'>Registry</Link> &gt; Home Fund
      </div>
      <div className='m-auto max-w-[1200px] px-12 pb-8'>
        <ItemLayout image='funds_image.jpg' alt='New house render'>
          <h2 className='text-2xl'>New Home Funds</h2>
          <p className='text-base'></p>
          <p className='text-base pt-4'>Thank you for wanting to contribute directly to our new home funds! Our new home
            is under construction and is estimated to be ready in 2023. Any funds gifted will be used for
            new-home things including furniture and some post-build additions, such as Solar Panels and a deck.</p>
          <p className='text-base pt-2'>If you'd like us to send you details with how to transfer, enter your email below and
            hit the button and we will send you our bank details.</p>
          {
            {
              'submitted': <p className='text-base mt-6'>Thank you! You should receive an email soon with details on how you can transfer.</p>,
              'error': <p className='text-base mt-6'>Sorry, something went wrong. Try refreshing and trying again?</p>
            }[state] || <Form email={email} setEmail={setEmail} loading={state === 'loading'} submit={submit}
                              buttonContent='Send me the details!'/>
          }
        </ItemLayout>
      </div>
    </section>
  )
}

export default TransferGuide;