import {NavLink} from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavItem = ({to, children}) => <div className='inline-block'>
  <NavLink to={to} className={({isActive}) => classNames(isActive ? 'underline' : '', 'mr-4 whitespace-nowrap')}>
    {children}
  </NavLink>
</div>


export default function Footer() {
  return (
    // bg-[#e0e0db]
    <footer
      className='bg-[#e0e0db] w-full py-[4vh] px-[4vw] text-[#253551] text-base items-center '>
      <div className='w-full min-h-[33vh] max-w-screen-2xl m-auto flex justify-between flex-wrap items-center'>
        <div>
          <h4>Michelle & Arend.</h4>
        </div>
        <div>
          <NavItem to='/'>When & Where</NavItem>
          <NavItem to='/story'>Our Story</NavItem>
          <NavItem to='/registry'>Registry</NavItem>
          <NavItem to='/recording'>Recording</NavItem>
          {/*<NavItem to='/rsvp'>RSVP</NavItem>*/}
          {/*{navigation.map((item, i) => (*/}
          {/*  <a*/}
          {/*    key={item.name}*/}
          {/*    href={item.href}*/}
          {/*    className={navItemClasses(item, i)}>{item.name}</a>*/}
          {/*))}*/}
        </div>
      </div>
    </footer>
  )
}