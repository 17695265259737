import InputLabel from "../InputLabel/InputLabel";

export const TextInput = ({name, label, type, value, onChange, placeholder, valid, disabled, ...props}) => {
  if (type === undefined || type === '') {
    type = 'text'
  }
  if (valid === undefined) {
    valid = true;
  }
  return (
    <div {...props}>
      <InputLabel htmlFor={name} className={!valid ? 'text-red-700' : undefined}>{label}</InputLabel>
      <input name={name}
             type={type}
             value={value}
             onChange={onChange}
             placeholder={placeholder}
             disabled={disabled}
             className={`mt-1 block w-full shadow-sm rounded-sm p-2 border bg-gray-50
                        focus:ring-marriage-blue focus:border-marriage-blue 
                        ${!valid ? 'border-red-700' : 'border-gray-300'}
                        ${disabled ? 'text-gray-500' : ''}`}/>
    </div>
  )
}

export default TextInput;