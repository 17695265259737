import {useState} from "react";
import PlaceholderImage from '../../../assets/our story/210321_Arend_and_Michelle_1491_WebRes.jpg'

const ItemLayout = ({image, alt, children}) => {
  const [imgSrc, setImgSrc] = useState(`${process.env.REACT_APP_REGISTRY_IMAGE_SOURCE}/${image}`)

  return (
    <div className='flex flex-col gap-12 md:flex-row'>
      <div className='flex-1 max-w-xs m-auto'>
        <div className='w-full overflow-hidden'>
          <img src={imgSrc} alt={alt} onError={() => {
            setImgSrc(PlaceholderImage)
          }}/>
        </div>
      </div>
      <div className='flex-1'>
        {children}
      </div>
    </div>)
}

export default ItemLayout;