import Button from "../../../components/Button/Button";

const Loader = () => <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 text-marriage-blue m-auto' viewBox="0 0 38 38">
  <defs>
    <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
      <stop stopColor='#253551' stopOpacity="0" offset="0%"/>
      <stop stopColor='#253551' stopOpacity=".631" offset="63.146%"/>
      <stop stopColor='#253551' offset="100%"/>
    </linearGradient>
  </defs>
  <g fill="none" fillRule="evenodd">
    <g transform="translate(1 1)">
      <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s"
                          repeatCount="indefinite"/>
      </path>
      <circle cx="36" cy="18" r="1">
        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s"
                          repeatCount="indefinite"/>
      </circle>
    </g>
  </g>
</svg>;

export const Form = ({submit, email, setEmail, buttonContent, loading}) => <form onSubmit={submit}>
  <div className='flex flex-wrap gap-4 mt-6'>
    <div className='flex-auto basis-8/12'>
      <label htmlFor='email' className='block text-sm text-gray-700 sr-only'>Email *</label>
      <input type='email' name='email'
             value={email} onChange={(ev) => setEmail(ev.target.value)}
             disabled={loading}
             className='block w-full shadow-sm rounded-sm p-2 border bg-gray-50 focus:ring-marriage-blue focus:border-marriage-blue'
             placeholder='Email'/>
    </div>
    <Button type='submit'
            className='flex-auto  px-8 py-2 bg-marriage-blue hover:bg-white text-white hover:text-marriage-blue'
            disabled={loading}>{loading ? <Loader /> : buttonContent}</Button>
  </div>
</form>