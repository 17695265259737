const WebLink = ({href, isExternal, children, ...props}) => {
  const linkOptions = !isExternal ? {} : {
    target: '_blank',
    rel: 'noreferrer'
  }

  return (<a href={href} className='hover:text-marriage-blue'
             {...linkOptions}
             {...props}>
    <span className='underline decoration-1 underline-offset-2 '>{children}</span> {isExternal ?
    <svg xmlns="http://www.w3.org/2000/svg" className="h-[0.75em] inline-block align-middle relative bottom-[0.1em]" fill="none" viewBox="0 0 24 24"
         stroke="currentColor"
         strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
    </svg> : undefined}
  </a>)
}

export default WebLink