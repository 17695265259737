import {useRef} from "react";
import Flickity from 'react-flickity-component'

import BlurbImage from '../../assets/our story/210321_Arend_and_Michelle_1491_WebRes.jpg'
import Carousel_0254 from '../../assets/our story/210321_Arend_and_Michelle_0254_B&White_WebRes.jpg'
import Carousel_0286 from '../../assets/our story/210321_Arend_and_Michelle_0286_WebRes.jpg'
import Carousel_0312 from '../../assets/our story/210321_Arend_and_Michelle_0312_WebRes.jpg'
import Carousel_0350 from '../../assets/our story/210321_Arend_and_Michelle_0350_WebRes.jpg'
import Carousel_0454 from '../../assets/our story/210321_Arend_and_Michelle_0454_B&White_WebRes.jpg'
import Carousel_0470 from '../../assets/our story/210321_Arend_and_Michelle_0470_WebRes.jpg'
import Carousel_0501 from '../../assets/our story/210321_Arend_and_Michelle_0501_B&White_WebRes.jpg'
import Carousel_0521 from '../../assets/our story/210321_Arend_and_Michelle_0521_WebRes.jpg'
import Carousel_0693 from '../../assets/our story/210321_Arend_and_Michelle_0693_WebRes.jpg'
import Carousel_0788 from '../../assets/our story/210321_Arend_and_Michelle_0788_WebRes.jpg'
import Carousel_1471 from '../../assets/our story/210321_Arend_and_Michelle_1471_B&White_WebRes.jpg'
import Carousel_1509 from '../../assets/our story/210321_Arend_and_Michelle_1509_WebRes.jpg'
import Carousel_1984 from '../../assets/our story/210321_Arend_and_Michelle_1984_B&White_WebRes.jpg'


export default function Story() {
  const flkty = useRef(null);

  function next() {
    flkty.current.next(true);
  }

  function prev() {
    flkty.current.previous(true);
  }

  return (
    <>
      <section className='flex items-center mt-32 h-[25vh] md:h-[33vh] justify-center items-center text-center'>
        <h1 className='text-marriage-blue text-4xl md:text-5xl'>How it all began</h1>
      </section>
      <section className='md:min-h-screen px-[10vw] pt-[5vh] pb-[10vh]'>
        <div className='flex flex-col xl:flex-row justify-center gap-x-20'>
          <img src={BlurbImage} alt="Michelle and Arend leaning into each other outside parliament"
               className='top-0 xl:w-1/2 max-w-2xl h-full min-h-[31rem] max-h-[50vw] xl:max-h-full object-cover'/>
          <div>
            <p className='py-4'>—</p>
            <p>Back in 2018, we both swiped right on each other. We had our first date in
              a small cafe in North Melbourne, and after spending the afternoon together finding out how much we
              have in common, we started dating. Four years and a corgi later, we are excited to take it to the next
              level.</p>
            <p className='pt-4'>While we've technically been married since March 2021, due to COVID we were only
              allowed a handful of guests. Now the borders have been opened and the restrictions have eased, we are
              inviting our friends and family to celebrate with us at a Nuptial Mass in a Catholic Church to
              complete our vows in a christian setting.</p>
          </div>
        </div>
      </section>
      <section className='bg-white pb-[10vh]'>
        <div className='px-[10vw] py-[10vh] text-center'>
          <h2 className='my-[10vh] text-5xl'>And the rest is history</h2>
        </div>
        <div className='overflow-hidden'>
          <Flickity
            flickityRef={c => flkty.current = c}
            options={{
              wrapAround: true,
              pageDots: false,
              prevNextButtons: false,
              initialIndex: 2,
              lazyLoad: 2
            }}
            static
          >
            <img data-flickity-lazyload-src={Carousel_0286}
                 alt="Michelle & Arend hug Harmen at the lockdown wedding ceremony"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0254} alt="Michelle & Arend holding marriage certificate"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0788} alt="Arend & Michelle kiss under an umbrella"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0312}
                 alt="Outdoor group shot of all the attendees of the lockdown wedding ceremony"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0454} alt="Arend, Michelle, Glenn & Clara"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0470} alt="Arend, Michelle, Freke & Jack"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_1509} alt="Michelle and Arend"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0501} alt="Arend, Michelle, Jasper & Mei"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0693} alt="Arend & Freke"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0350} alt="Michelle, Arend, Anneke and Harmen"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_1984} alt="Michelle and Arend hugging"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_1471} alt="Michelle holding flowers looking at Arend"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_0521} alt="All the ladies at the lockdown wedding ceremony"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
          </Flickity>
          <div className='flex justify-center gap-10 py-6 sr'>
            <button type='button' onClick={prev}>
              <span className='sr-only'>Previous image</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"/>
              </svg>
            </button>
            <button type='button' onClick={next}>
              <span className='sr-only'>Next image</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}