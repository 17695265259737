import {NavLink} from "react-router-dom";
import {useAuthState} from "../../../contexts/AuthContext";

const LoginModule = () => {
  const authState = useAuthState();

  let state = authState.loading ? 'loading' : 'unauthenticated';
  if (!authState.loading) {
    state = authState.loggedIn ? 'authenticated' : state;
  }

  return (
    <div className='m-auto pb-8 flex flex-row '>
      <div className='flex-1'>
        <NavLink to='/registry/management'>Management</NavLink> | <NavLink to='/registry/management/add'>Add</NavLink>
      </div>
      <div className='flex-1 text-right'>
        {
          {
            'loading': <p className='text-base'>Checking login status...</p>,
            'authenticated': <p className='text-base'>{authState?.principle?.userDetails} | <a href='/logout'>Logout</a></p>,
            'unauthenticated': <p className='text-base text-red-700'><a href='/login'>Login</a></p>
          }[state]
        }
      </div>
    </div>
  )
}

export default LoginModule;