import { createFriendlyApiError, tryHandleError} from "../Shared/Common";

const FetchItems = async () => {
  let response;
  try {
    response = await fetch('/api/registry')
  } catch
    (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  try {
    return await response.json();
  } catch (e) {
    console.error('failed to read json response', e)
    const [, error] = createFriendlyApiError('json-read-failure', response.status, await response.text());
    return error;
  }
}

const ReserveItem = async (id, email) => {
  let response;
  try {
    response = await fetch(`/api/registry/${id}/reserve`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
  } catch
    (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

const UnreserveItem = async (id, email) => {
  let response;
  try {
    response = await fetch(`/api/registry/${id}/reserve`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
  } catch
    (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

const AddItem = async (item, image) => {
  let response;
  try {
    const data = new FormData();
    data.append('image', image);
    data.append('metadata', JSON.stringify(item));

    response = await fetch(`/api/registry/add`, {
      method: 'POST',
      body: data
    })
  } catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

const RemoveItem = async (id) => {
  let response;
  try {
    response = await fetch(`/api/registry/delete/${id}`, {
      method: 'DELETE'
    })
  } catch
    (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

const EditItem = async (item, image) => {
  let response;
  try {
    const data = new FormData();
    data.append('image', image);
    data.append('metadata', JSON.stringify(item));

    response = await fetch(`/api/registry/edit`, {
      method: 'POST',
      body: data
    })
  } catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

const TransferGuide = async (email) => {
  let response;
  try {
    response = await fetch(`/api/transfer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
  } catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(response);
  if (errored) {
    return error;
  }

  return {}
}

export {FetchItems, ReserveItem, UnreserveItem, AddItem, RemoveItem, EditItem, TransferGuide};